<template>
    <div class="grid gap-20">
        <ActionBar title="My Carriers"></ActionBar>


        <TableView :head="tableHead" :size="columnsSizes" v-if="(referralsStore.carriers.length > 0)"
            :loading="isLoading(['GetListOfCarriers'])">
            <TableRow :size="columnsSizes" :key="item._id" v-for="(item, key) of referralsStore.carriers">
                <div class="text-overflow"><a href="" @click.prevent>{{ item.companyName }}</a></div>
                <div class="text-overflow">{{ item.mc }}</div>
                <div class="text-overflow">
                    <a href="" @click.prevent>${{ CONVERT_PRICE(Number(item.loads.totalEarned).toFixed(2)) }}</a>
                </div>
                <div class="text-overflow">{{ item.loads.count }} {{ item.loads.count === 1 ? 'load' : 'loads' }}</div>
            </TableRow>
        </TableView>

        <div v-else>
            <div v-if="isLoading(['GetListOfCarriers'])" class="spinner-flex">
                <Spinner />
            </div>
            <div v-else>
                <NoDataMessage text="Nothing found" />
            </div>
        </div>

    </div>
</template>

<script>
import { mapStores } from 'pinia';
import { useReferralsStore } from '../../store/Referrals';

import StatsItem from '../../components/StatsItem.vue'

export default {
    components: {
        StatsItem
    },
    data() {
        return {
            tableHead: [
                { name: 'Company Name' },
                { name: 'MC #' },
                { name: 'Earned' },
                { name: 'No. of loads' },
            ],
            columnsSizes: [
                'minmax(50px, 1fr)', // full name
                'minmax(50px, 100px)',
                'minmax(50px, 100px)',
                'minmax(50px, 100px)',
            ],
        }
    },
    computed: {
        ...mapStores(useReferralsStore)
    },
    methods: {
        getCarriers() {
            this.ajax("GetListOfCarriers", {
                url: '/referrals/user/carriers',
                method: 'GET'
            }, (err, body) => {
                if (!err) {
                    this.referralsStore.setCarriers(body);
                }
            });
        }
    },
    mounted() {
        this.getCarriers();
    }
}
</script>

<style lang="scss" scoped></style>