<template>
    <div class="stats-item" :class="[{ 'horizontal' : horizontal }]">
        <div class="icon-holder" v-if="icon">
            <span><i :class="icon || 'fa-solid fa-dollar-sign'"></i></span>
        </div>
        <div class="content-item">
            <div class="stats-content"><slot></slot></div>
            <div class="text">{{ text }}</div>
        </div>
        <div v-if="loading" class="spinner-flex"><Spinner size="small"/></div>
    </div>
</template>

<script>
    export default {
        props: ['icon', 'text', 'loading', 'horizontal']
    }
</script>

<style lang="scss" scoped>
.stats-item {
    padding: 20px 30px;
    background: $themeColor1;
    border-radius: 20px;
    display: grid;
    row-gap: 20px;
    position: relative;
    border: 1px solid $borderColor;
    box-shadow: $boxShadow;
    .icon-holder {
        width: 50px;
        height: 50px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $bg;
        font-size: 18px;
    }
    .content-item {
        display: grid;
        .text {
            color: $textShade;
            font-size: 12px;
        }
    }
    .stats-content {
        font-size: 32px;
        font-weight: 300;
        height: 70px;
    }
    &.horizontal {
        grid-template-columns: 50px minmax(0, 1fr);
        align-items: center;
        gap: 20px;
        padding: 20px;
        .stats-content {
            height: auto;
            font-size: 28px;
        }
    }
}

.spinner-flex {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    background: rgba(255,255,255,0.5);
    z-index: 3;
    border-radius: 20px;
}
</style>